import Prismic from "@prismicio/client";

export { Prismic };
export const apiEndpoint = "https://mantrachat.cdn.prismic.io/api/v2";

// Funcion para inicializar el cliente
export const Client = (req = null, options = {}) => {
  let fullOptions = Object.assign({}, options);
  if (req) {
    fullOptions.req = req;
  }
  return Prismic.client(apiEndpoint, fullOptions);
};
// Cliente ya inicializado sin opciones adicionales
export const client = Client();

// Link Resolver
export const linkResolver = (doc) => {
  return "/";
};
