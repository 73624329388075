import React from "react";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";

import HeaderModal from "./HeaderModal";
import FooterModal from "./FooterModal";
import BodyModal from "./BodyModal";

function ModalAgentAssignment({
  toggleAgentAssignment,
  modal,
  agent,
  setAgent,
  membersOfGroup,
  saveAgentAssignment,
  contactSelected,
}) {
  const stateLoadingGeneral = useSelector((state) => state.General.loading);

  return (
    <Modal
      isOpen={modal}
      centered
      className={stateLoadingGeneral ? "loadingLocked__modal__blur" : ""}
    >
      <HeaderModal
        toggle={toggleAgentAssignment}
      />
      <BodyModal
        membersOfGroup={membersOfGroup}
        agent={agent}
        setAgent={setAgent}
        contactSelected={contactSelected}
      />
      <FooterModal
        toggle={toggleAgentAssignment}
        saveAgentAssignment={saveAgentAssignment}
      />
    </Modal>
  );
}

export default ModalAgentAssignment;
