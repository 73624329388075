import React from "react";
import { useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { getTranslation, ModalChatStatusTagsLanguages } from "./language";

function ModalChatStatusTags({ show, toggle, currentContact }) {
  const language = useSelector((state) => state.Group.language);
  const translate = (key) => getTranslation(ModalChatStatusTagsLanguages, language, key);

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      centered={true}
    >
      <ModalHeader toggle={toggle}>
        {translate("Chat status tags history")}
      </ModalHeader>
      <ModalBody>
        {Array.isArray(currentContact?.chatStatusTags) && currentContact.chatStatusTags.length > 0 ? (
          <ul className="pl-3">
            {currentContact.chatStatusTags.map((i, idx) => (
              <li key={idx}>
                {idx == currentContact.chatStatusTags.length - 1 ? (
                  <strong>
                      {`${i.tags.map(item=>item).join(", ")} (${translate("current")})`}
                  </strong>
                ) : (
                  <span>
                      {`${i.tags.map(item=>item).join(", ")}`}
                  </span>
                )}
                <span className="float-right">
                  <small>
                    {`${translate("From")} ${new Date(i.assignedAt).toLocaleDateString('en-gb')}`}
                  </small>
                </span>
              </li>
            ))}
          </ul>
        ) : (
          translate("No tags assigned yet")
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>{translate("Close")}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalChatStatusTags;
