import { SET_NOTIFICATIONS, SET_TOTAL_NOTIFICATIONS } from "./constants";

const initialState = {
  notifications: [],
  totalNotifications: 0,
};

const Notifications = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case SET_TOTAL_NOTIFICATIONS:
      return { ...state, totalNotifications: action.payload };
    default:
      return state;
  }
};

export default Notifications;
