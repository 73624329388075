import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Button } from "reactstrap";
import Select from "react-select";

import { changeChatStatusTag } from "../../redux/actions";
import { getTranslation, ModalChangeChatStatusTagLanguages } from "./language";

function ModalChangeChatStatusTag({ show, toggle, currentGroup, currentContact }) {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.Group.language);
  const translate = (key) => getTranslation(ModalChangeChatStatusTagLanguages, language, key);
  const [newTag, setNewTag] = useState([]);
  const currentTag = Array.isArray(currentContact?.chatStatusTags) && currentContact.chatStatusTags.length > 0 ? currentContact.chatStatusTags[currentContact.chatStatusTags.length - 1] : null;

  const [tagsOptions, setTagsOptions] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);


  useEffect(() => {
    if (show) {
      let newTagsOptions = [];
      let newDefaultOptions = [];
      let newTags = [];

      if(Array.isArray(currentGroup?.chatStatusTags)){
        currentGroup.chatStatusTags.map((tag) => {
          newTagsOptions.push({value:tag, label:tag})
        })
        setTagsOptions(newTagsOptions);
      }
      if(Array.isArray(currentContact?.chatStatusTags) && currentContact.chatStatusTags.length > 0){
        if(currentContact.chatStatusTags[currentContact.chatStatusTags.length - 1]?.tags){
          currentContact.chatStatusTags[currentContact.chatStatusTags.length - 1].tags.map(item => {
            newDefaultOptions.push({value:item, label:item});
            newTags.push(item);
          })
        }
        setDefaultOptions(newDefaultOptions);
      }
      setNewTag(newTags);
    }
  }, [show]);

  const handleChangeChatStatusTag = () => {
    if (newTag.length === 0) {
      alert(translate('Select a status tag'))
      return;
    }
    dispatch(changeChatStatusTag(newTag)).then(() => {
      toggle();
    }).catch(err => {
      alert(translate('Error saving chat status tag'));
    });
  }

  const handleSelectTags = (value) => {
    let newValue = [];
    value.map(tag => newValue.push(tag.value));
    setNewTag(newValue);
    console.log("V",value,newValue)
  }

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      centered={true}
    >
      <ModalHeader toggle={toggle}>
        {translate('Change chat status tags')}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup className="mb-4">
            <Label>{translate('Select tags')}</Label>
            <Select
              onChange={(selected) => handleSelectTags(selected)}
              isMulti
              closeMenuOnSelect={false}
              name="tags"
              options={tagsOptions}
              defaultValue={defaultOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={translate('Select')}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>{translate('Cancel')}</Button>
        <Button color="primary" onClick={handleChangeChatStatusTag}>{translate('Change')}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalChangeChatStatusTag;
