import {
  SET_ACTIVE_TAB,
  OPEN_USER_PROFILE_SIDEBAR,
  CLOSE_USER_PROFILE_SIDEBAR,
  SET_CONVERSATION_NAME_IN_OPEN_CHAT,
  OPEN_TEMPLATES_SIDEBAR,
  CLOSE_TEMPLATES_SIDEBAR,
  OPEN_SPERANT_EXTENSION_SIDEBAR,
  CLOSE_SPERANT_EXTENSION_SIDEBAR,
  OPEN_IA_ANALYSIS_SIDEBAR,
  CLOSE_IA_ANALYSIS_SIDEBAR

} from "./constants";

export const setActiveTab = (tabId) => ({
  type: SET_ACTIVE_TAB,
  payload: tabId,
});

export const openUserSidebar = () => ({
  type: OPEN_USER_PROFILE_SIDEBAR,
});

export const closeUserSidebar = () => ({
  type: CLOSE_USER_PROFILE_SIDEBAR,
});

export const setconversationNameInOpenChat = (conversationName) => ({
  type: SET_CONVERSATION_NAME_IN_OPEN_CHAT,
  payload: conversationName,
});

export const openTemplatesSidebar = () => ({
  type: OPEN_TEMPLATES_SIDEBAR,
});

export const closeTemplatesSidebar = () => ({
  type: CLOSE_TEMPLATES_SIDEBAR,
});

export const openSperantExtensionSidebar = () => ({
  type: OPEN_SPERANT_EXTENSION_SIDEBAR,
});

export const closeSperantExtensionSidebar = () => ({
  type: CLOSE_SPERANT_EXTENSION_SIDEBAR,
});

export const openIaAnalysisSidebar = () => ({
  type: OPEN_IA_ANALYSIS_SIDEBAR,
});

export const closeIaAnalysisSidebar = () => ({
  type: CLOSE_IA_ANALYSIS_SIDEBAR,
});
