import React from "react";
import Loading from "../../components/general/Loading";
import LeftSidebarMenu from "./LeftSidebarMenu.jsx";
import { useSelector } from "react-redux";
import withAuthentication from "./withAuthentication";
import UserProfileSidebar from "../../components/UserProfileSidebar.jsx";

const Index = (props) => {
  const loading = useSelector((state) => state.Auth.loading);
  const activeContact = useSelector((state) => state.Contact.activeContact);
  const isUserSidebarDefaultOpen = useSelector((state) => state.Auth.isUserSidebarDefaultOpen);

  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (
        <div className="layout-wrapper d-lg-flex">
          <LeftSidebarMenu />
          {props.children}
          {isUserSidebarDefaultOpen && (
            <div style={{ width: "380px", height: "100vh" }}>
              <UserProfileSidebar user={activeContact} />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withAuthentication(Index);
