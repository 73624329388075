import React from "react";
import { Collapse, CardBody, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CustomCollapse(props) {
  const { isOpen, toggleCollapse } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Link to="#" onClick={toggleCollapse} className="text-dark">
        <CardHeader id="profile-user-headingOne">
          <h5 className="d-flex justify-content-between align-items-center font-size-14 m-0">
            {props.iconClass && (
              <i
                className={
                  props.iconClass + " mr-2 align-middle d-inline-block"
                }
              ></i>
            )}
            {props.title}
            <i
              className={
                isOpen
                  ? "ml-1 mdi mdi-chevron-up float-right accor-plus-icon"
                  : "ml-1 mdi mdi-chevron-right float-right accor-plus-icon"
              }
            ></i>
          </h5>
        </CardHeader>
      </Link>

      <Collapse isOpen={isOpen}>
        <CardBody>{props.children}</CardBody>
      </Collapse>
    </React.Fragment>
  );
}

export default CustomCollapse;
