import React from "react";
import { useSelector } from 'react-redux';
import { Button, ModalFooter } from "reactstrap";
import { getTranslation, FooterModalLanguages } from "./language";

function FooterModal({
  toggle,
  saveAgentAssignment,
}) {
  const language = useSelector((state) => state.Group.language);
  const translate = (key) => getTranslation(FooterModalLanguages, language, key);

  return (
    <ModalFooter>
      <Button
        type="button"
        color="link"
        onClick={() => {
          toggle();
        }}
      >
        {translate("Close")}
      </Button>
      <Button
        type="button"
        color="primary"
        onClick={() => {
          saveAgentAssignment();
        }}
      >
        {translate("Save")}
      </Button>
    </ModalFooter>
  );
}

export default FooterModal;
