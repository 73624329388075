import { APIClient } from "../../helpers/apiClient";
import { isPublisher } from "../../helpers/checkRoles";

import {
  SET_SCREEN,
  GET_WHATSAPPFLOWS,
  SET_ACTIVE_SIDEBAR_TAB,
  GET_WHATSAPPFLOWS_ERROR,
  GET_WHATSAPPFLOWS_SUCCESS,
  SET_WHATSAPPFLOWS,
  SET_SORTED_WHATSAPPFLOWS,
  SEND_WHATSAPPFLOW,
  CHANGE_VIEW_WHATSAPPFLOWS,
  CHANGE_USER_IN_WHATSAPPFLOWS,
  CREATE_FLOW_INPUT_MODAL,
  CREATE_FLOW_MODAL,
  SET_CATEGORY_CREATE_FLOW,
  CHANGE_SELECTED_SCREEN,
  SET_NAME_CREATE_FLOW,
  DELETE_ELEMENT,
  SET_PARAMS_TO_UPDATE,
  VALIDATE_SCREEN_ELEMENTS,
  SET_ARCHIVED_WHATSAPPFLOWS,

} from "./constants";

const apiClient = new APIClient();
const { get, create, put, remove, patch, post } = apiClient;

export const setWhatsAppFlows = (flows) => {
  return {
    type: SET_WHATSAPPFLOWS,
    payload: flows,
  };
};

export const setArchivedWhatAppFlows = (flows) => {
  return {
    type: SET_ARCHIVED_WHATSAPPFLOWS,
    payload: flows,
  };
};

export const setParamsToUpdate = (params) => ({
  type: SET_PARAMS_TO_UPDATE,
  payload: params,
});

export const deleteElement = (screenIndex, elementIndex) => ({
  type: DELETE_ELEMENT,
  payload: { screenIndex, elementIndex },
});

export const deleteElementThunk =
  (screenIndex, elementIndex) => (dispatch, getState) => {
    // Aquí puedes añadir lógica adicional si es necesario
    dispatch(deleteElement(screenIndex, elementIndex));

    // Ejemplo de lógica adicional:
    // const state = getState();
    // if (state.whatsAppFlows.screens[screenIndex].layout.children.length === 0) {
    //   dispatch(someOtherAction());
    // }
  };

export const getWhatsAppFlows = () => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    if (currentGroup) {
      const { id: groupId } = currentGroup;
      const response = await get("/wsflows", {
        params: { groupId, archived: false },
      });

      const currentGroupId = getState().Group.currentGroup?.id;
      if (currentGroupId === groupId) {
        dispatch(setWhatsAppFlows(response));
      }
      const archived = await get("/wsflows", {
        params: { groupId, archived: true },
      });

      if (currentGroupId === groupId) {
        dispatch(setArchivedWhatAppFlows(archived));
      }
      //agregar tambien los archivados
    }
  } catch (e) {
    console.log(e);
  }
};
export const changeSelectedScreen = (screenIndex) => {
  return {
    type: CHANGE_SELECTED_SCREEN,
    payload: screenIndex,
  };
};

export const changeViewWhatsAppFlows = (payload) => ({
  type: CHANGE_VIEW_WHATSAPPFLOWS,
  payload,
});

export const changeUserInWhatsAppFlows = (payload) => ({
  type: CHANGE_USER_IN_WHATSAPPFLOWS,
  payload,
});

export const changeWhatsAppFlowInputModal = (payload) => ({
  type: CREATE_FLOW_INPUT_MODAL,
  payload,
});

export const changeWhatsAppFlowModal = (payload) => ({
  type: CREATE_FLOW_MODAL,
  payload,
});

export const changeNameCreateFlow = (payload) => ({
  type: SET_NAME_CREATE_FLOW,
  payload,
});

export const changeCategoryCreateFlow = (payload) => ({
  type: SET_CATEGORY_CREATE_FLOW,
  payload,
});

export const setFirstScreen = (payload) => ({
  type: "CREATE_FIRST_SCREEN",
  payload,
});

export const updateTextHeading = (id, field, value) => ({
  type: "UPDATE_TEXT_HEADING",
  payload: { id, field, value },
});

export const validateScreenElements = () => ({
  type: VALIDATE_SCREEN_ELEMENTS
});

export const updateWhatsAppFlow = () => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    const name = getState().WhatsAppFlows.name;
    const categories = getState().WhatsAppFlows.categories;
    const updateParams = getState().WhatsAppFlows.wsFlowParamsToUpdate;
    const screens = getState().WhatsAppFlows.screens;
    //const { name, content, gsId, order, file } = payload;

    const updatedScreens = screens.map(screen => ({
      ...screen,
      layout: {
        ...screen.layout,
        children: screen.layout.children.map(child => {
          const { isValid, ...childWithoutIsValid } = child;
          return childWithoutIsValid;
        })
      }
    }));

    const body = {
      appId: currentGroup.messagingAppData.appId,
      appToken: currentGroup.messagingAppData.appToken,
      name: name,
      categories: categories,
      id: updateParams.id,
      wsFlowId: updateParams.wsFlowId,
      flowJson: {
        version: updateParams.version,
        screens: updatedScreens,
      },
    }
    const wsFlow = await put(`/wsflows`, body);
    //dispatch(getWhatsAppFlows());
    //actualizar los flows actuales
  } catch (error) {
    //manjear e
    //console.log(error);
    throw error;
  }
};

export const changeScreens = (payload) => ({ type: "UPDATE_SCREENS", payload });

export const sendWhatsAppFlow = (payload) => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    const updateParams = getState().WhatsAppFlows.wsFlowParamsToUpdate;
    let body = {
      header: payload.header,
      footer: payload.footer,
      text: payload.text,
      action: payload.action,
      phone: payload.phone,
      appId: currentGroup.messagingAppData.appId,
      appToken: currentGroup.messagingAppData.appToken,
    };
    const response = await post(`/wsflows/${updateParams.id}/send`, body);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const createWhatsAppFlow = (payload) => async (dispatch, getState) => {
  const currentGroup = getState().Group.currentGroup;
  const name = getState().WhatsAppFlows.name;
  const categories = getState().WhatsAppFlows.categories;

  try {
    const wsFlow = await create(`/wsflows`, {
      appId: currentGroup.messagingAppData.appId,
      appToken: currentGroup.messagingAppData.appToken,
      categories: categories,
      groupId: currentGroup.id,
      name: name,
    });


    dispatch(changeScreens(wsFlow?.flowJson?.screens));
    let body = {
      id: wsFlow._id,
      version: wsFlow.flowJson.version,
      wsFlowId: wsFlow.wsId,
    };
    dispatch(setParamsToUpdate(body));
    //dispatch(up)
    return wsFlow;
  } catch (error) {
    throw error;
  }
};

export const deleteWhatsAppFlow =
  (wsFlowId) => async (dispatch, getState) => {
    try {
      await remove(`/wsflows/${wsFlowId}`);
    } catch (error) {
      console.log(error);
      throw Error("Error deleting template");
    }
  };

export const publishFlow = (payload) => async (dispatch, getState) => {
  try {
    const { id, wsFlowId } = payload;
    const currentGroup = getState().Group.currentGroup;
    const updateParams = getState().WhatsAppFlows.wsFlowParamsToUpdate;
    const response = await post(`/wsflows/publish`, {
      appId: currentGroup.messagingAppData.appId,
      appToken: currentGroup.messagingAppData.appToken,
      id,
      wsFlowId,
    });
  } catch (error) {
    throw error;
  }
};

export const setScreen = (payload) => ({
  type: "SET_SCREEN",
  payload,
});
