import React from "react";
import { useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { getTranslation, indexLanguages } from "./language";

const getLabelForAssignedBy = (assignedBy, translate) => {
  switch (assignedBy) {
    case 'SOURCE_API_INTEGRATION':
      return translate("ByAPIIntegration");
    case 'SOURCE_USER_ADMIN':
      return translate("ByAdminUser");
    case 'SOURCE_USER_PUBLISHER':
      return translate("ByAgentUser");
    case 'SOURCE_SYSTEM':
      return translate("ByAutomaticAssignment");
    case 'SOURCE_CHATBOT':
      return translate("ByChatbot");
    case 'SOURCE_API':
      return translate("ByAPI");
    default:
      return '';
  }
}

function ModalAssignHistory({
  show,
  toggle,
  contactSelected,
  membersOfGroup,
}) {
  const language = useSelector((state) => state.Group.language);
  const translate = (key) => getTranslation(indexLanguages, language, key);

  const agentsOfGroup = membersOfGroup?.filter(member => member.role && member.role === "ROLE_PUBLISHER");

  const findAgentName = (userTagId) => {
    if (Array.isArray(agentsOfGroup) && userTagId) {
      const user = agentsOfGroup.find(agent => agent.tagId == userTagId);
      return user && user.email;
    }
    return ` ${translate("WithID")} ${userTagId}`;
  };

  return (
    <Modal isOpen={show} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>{translate("AssignmentHistory")}</ModalHeader>
      <ModalBody>
        {contactSelected && Array.isArray(contactSelected.userTagIds) ? (
          <ul className="pl-4 mb-0">
            {contactSelected.userTagIds.map((assignation, idx) => {
              return (
                <li key={idx} className="mb-2">
                  {idx !== 0 && <hr className="mt-0 mb-2" />}
                  {`${translate("AssignedToAgent")} `}
                  <strong>{findAgentName(assignation.userTagId)}</strong>
                  {assignation.assignedAt && !isNaN(new Date(assignation.assignedAt).getTime()) && (
                    <>
                      <br />
                      {`${translate("On")} ${new Date(assignation.assignedAt).toLocaleDateString('en-gb')} ${new Date(assignation.assignedAt).toLocaleTimeString('en-us', { timeStyle: 'short' })}`}
                      {assignation.assignedBy && (() => {
                        const labelForAssignedBy = getLabelForAssignedBy(assignation.assignedBy, translate);
                        if (!labelForAssignedBy) return null;
                        return (
                          <>
                            <br />
                            <span className="font-size-13 text-muted">
                              {labelForAssignedBy}
                              {assignation.assignedByUserId && (() => {
                                const user = membersOfGroup.find(m => m.id === assignation.assignedByUserId);
                                return user ? ` (${user.email})` : '';
                              })()}
                            </span>
                          </>
                        );
                      })()}
                    </>
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          <strong>{translate("ContactHasNoAssignments")}</strong>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>{translate("Close")}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalAssignHistory;
