import React from "react";
import { ModalBody, Form, FormGroup, Label, Input } from "reactstrap";
import { useSelector } from 'react-redux';
import { getTranslation, BodyModalLanguages } from "./language";

function BodyModal({
  membersOfGroup,
  agent,
  setAgent,
  contactSelected,
}) {
  const language = useSelector((state) => state.Group.language);
  const translate = (key) => getTranslation(BodyModalLanguages, language, key);
  const agentsOfGroup = membersOfGroup?.filter(member => member.role && member.role === "ROLE_PUBLISHER");
  // agentsOfGroup = agentsOfGroup?.filter(agent => agent.tagId !== contactSelected.userTagId);
  const agentAssigned = agentsOfGroup?.find(agent => {
    if(agent.tagId && contactSelected.userTagId) {
      return agent.tagId === contactSelected.userTagId;
    }
  });

  return (
    <ModalBody className="p-4">
      <Form>
        {(
          <FormGroup className="mb-4">
            <Label htmlFor="agent-select">
              {translate("AvailableAgents")}
            </Label>
            <Input
              type="select"
              name="agent"
              id="agent-select"
              onChange={(e) => {
                setAgent(e.target.value);
              }}
              value={agent}
            >
              {agentAssigned
                ? <option value="" hidden>
                    {agentAssigned.name} ({translate("CurrentAgent")})
                  </option>
                : <option value="" hidden>
                    {translate("NoAgentAssigned")}
                  </option>}
              {agentsOfGroup?.map((agentOfGroup, key) => (
                (!agentOfGroup.tagId || agentOfGroup.tagId !== contactSelected.userTagId) &&
                <option key={key} value={agentOfGroup.id}>
                  {agentOfGroup.name}
                </option>
              ))}
            </Input>
          </FormGroup>
        )}
      </Form>
    </ModalBody>
  );
}

export default BodyModal;
