export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const OPEN_USER_PROFILE_SIDEBAR = "OPEN_USER_PROFILE_SIDEBAR";
export const CLOSE_USER_PROFILE_SIDEBAR = "CLOSE_USER_PROFILE_SIDEBAR";
export const SET_CONVERSATION_NAME_IN_OPEN_CHAT =
  "SET_CONVERSATION_NAME_IN_OPEN_CHAT";
export const OPEN_TEMPLATES_SIDEBAR = "OPEN_TEMPLATES_SIDEBAR";
export const CLOSE_TEMPLATES_SIDEBAR = "CLOSE_TEMPLATES_SIDEBAR";
export const OPEN_SPERANT_EXTENSION_SIDEBAR = "OPEN_SPERANT_EXTENSION_SIDEBAR";
export const CLOSE_SPERANT_EXTENSION_SIDEBAR = "CLOSE_SPERANT_EXTENSION_SIDEBAR";
export const OPEN_IA_ANALYSIS_SIDEBAR = "OPEN_IA_ANALYSIS_SIDEBAR";
export const CLOSE_IA_ANALYSIS_SIDEBAR = "CLOSE_IA_ANALYSIS_SIDEBAR";
