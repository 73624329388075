export const GET_WHATSAPPFLOWS = "GET_WHATSAPPFLOWS";
export const SET_ACTIVE_SIDEBAR_TAB = "SET_ACTIVE_SIDEBAR_TAB";
export const GET_WHATSAPPFLOWS_ERROR = "GET_WHATSAPPFLOWS_ERROR";
export const GET_WHATSAPPFLOWS_SUCCESS = "GET_WHATSAPPFLOWS_SUCCESS";
export const SET_WHATSAPPFLOWS = "SET_WHATSAPPFLOWS";
export const SET_SORTED_WHATSAPPFLOWS = "SET_SORTED_WHATSAPPFLOWS";
export const SEND_WHATSAPPFLOW = "SEND_WHATSAPPFLOW";
export const CHANGE_VIEW_WHATSAPPFLOWS = "CHANGE_VIEW_WHATSAPPFLOWS"
export const CHANGE_USER_IN_WHATSAPPFLOWS = "CHANGE_USER_IN_WHATSAPPFLOWS";
export const CREATE_FLOW_INPUT_MODAL = "CREATE_FLOW_INPUT_MODAL";
export const CREATE_FLOW_MODAL = "CREATE_FLOW_MODAL";
export const SET_CATEGORY_CREATE_FLOW = "SET_CATEGORY_CREATE_FLOW";
export const SET_NAME_CREATE_FLOW = "SET_NAME_CREATE_FLOW";
export const CREATE_FIRST_SCREEN = "CREATE_FIRST_SCREEN";
export const UPDATE_TEXT_HEADING = "UPDATE_TEXT_HEADING";
export const UPDATE_SCREENS = "UPDATE_SCREENS";
export const CHANGE_SELECTED_SCREEN = "CHANGE_SELECTED_SCREEN";
export const DELETE_ELEMENT = "DELETE_ELEMENT";
export const SET_PARAMS_TO_UPDATE = "SET_PARAMS_TO_UPDATE";
export const VALIDATE_SCREEN_ELEMENTS = "VALIDATE_SCREEN_ELEMENTS";
export const SET_ARCHIVED_WHATSAPPFLOWS = "SET_ARCHIVED_WHATSAPPFLOWS";
//export const SET_SCREEN = "SET_SCREEN";