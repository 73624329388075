import { version } from "react";
import {
  SET_WHATSAPPFLOWS,
  SET_ACTIVE_SIDEBAR_TAB,
  CHANGE_VIEW_WHATSAPPFLOWS,
  CHANGE_USER_IN_WHATSAPPFLOWS,
  CREATE_FLOW_INPUT_MODAL,
  CREATE_FLOW_MODAL,
  SET_CATEGORY_CREATE_FLOW,
  SET_NAME_CREATE_FLOW,
  CREATE_FIRST_SCREEN,
  UPDATE_TEXT_HEADING,
  UPDATE_SCREENS,
  CHANGE_SELECTED_SCREEN,
  DELETE_ELEMENT,
  SET_PARAMS_TO_UPDATE,
  VALIDATE_SCREEN_ELEMENTS,
  SET_ARCHIVED_WHATSAPPFLOWS
} from "./constants";

const initialState = {
  name: "",
  categories: [],
  whatsAppFlows: [],
  archivedWhatsAppFlows: [],
  activeSidebarTab: "",
  view: "viewFlows",
  userInWhatsAppFlows: false,
  createFlowInputModal: false,
  createFlowModal: false,
  screens: [],
  selectedScreen: 0,
  firstNewScreen: {
    id: "WELCOME_SCREEN",
    title: "Welcome",
    terminal: true,
    success: true,
    data: {},
    layout: {
      type: "SingleColumnLayout",
      children: [
        {
          type: "TextHeading",
          text: "Hello World",
        },
        {
          type: "TextBody",
          text: "Let's start building things!",
        },
        {
          type: "Footer",
          label: "Complete",
          "on-click-action": {
            name: "complete",
            payload: {},
          },
        },
      ],
    },
  },
  wsFlowParamsToUpdate: {
    id: "",
    version: "",
    wsFlowId: "",
  },
};

const updateElementNames = (children) => {
  return children.map((child, newIndex) => {
    if (
      [
        "CheckboxGroup",
        "RadioButtonsGroup",
        "Dropdown",
        "TextInput",
        "TextArea",
        "OptIn",
      ].includes(child.type)
    ) {
      const nameParts = child.name.split("-");
      nameParts[nameParts.length - 1] = newIndex.toString();
      return { ...child, name: nameParts.join("-") };
    }
    return child;
  });
};

const validateElement = (element) => {
  switch (element.type) {
    case "TextHeading":
    case "TextSubheading":
      return !!(
        element.text &&
        element.text.trim().length > 0 &&
        element.text.trim().length <= 80
      );
    case "TextBody":
      return !!(
        element.text &&
        element.text.trim().length > 0 &&
        element.text.trim().length <= 4096
      );
    case "TextCaption":
      return !!(
        element.text &&
        element.text.trim().length > 0 &&
        element.text.trim().length <= 409
      );
    case "TextInput":
    case "TextArea":
      return !!(
        element.label &&
        element.label.trim().length > 0 &&
        element.label.trim().length <= 20
      );
    case "CheckboxGroup":
    case "RadioButtonsGroup":
      return !!(

        element["data-source"].length > 0 &&
        element["data-source"].length <= 20 &&
        
        element.label.trim().length > 0 &&

        element.description.trim().length <= 300 &&
        element["data-source"].every(
          (option) =>
            option.title.trim().length > 0 &&
            option.title.trim().length <= 30 &&
            option.description.trim().length <= 300
        )
      );
    case "Dropdown":
      return !!(
        element["data-source"] &&
        element["data-source"].length > 0 &&
        element["data-source"].length <= 20
      );
    case "Footer":
      return !!(
        element.label &&
        element.label.trim().length > 0 && element.label.trim().length <= 35 &&
        element["on-click-action"]
      );
    default:
      return true;
  }
};

const WhatsAppFlows = (state = initialState, action) => {
  switch (action.type) {
    case SET_ARCHIVED_WHATSAPPFLOWS:
      return {...state, archivedWhatsAppFlows: action.payload}
    case VALIDATE_SCREEN_ELEMENTS:
      const updatedScreens = state.screens.map((screen) => ({
        ...screen,
        layout: {
          ...screen.layout,
          children: screen.layout.children.map((child) => ({
            ...child,
            isValid: validateElement(child),
          })),
        },
      }));

      const isValid = updatedScreens.every((screen) =>
        screen.layout.children.every((child) => child.isValid)
      );

      return {
        ...state,
        screens: updatedScreens,
        isValid,
      };
    case SET_PARAMS_TO_UPDATE:
      return { ...state, wsFlowParamsToUpdate: action.payload };
    case SET_WHATSAPPFLOWS:
      return { ...state, whatsAppFlows: action.payload };
    case CHANGE_USER_IN_WHATSAPPFLOWS:
      return { ...state, userInWhatsAppFlows: action.payload };
    case CHANGE_VIEW_WHATSAPPFLOWS:
      return { ...state, view: action.payload };
    case SET_ACTIVE_SIDEBAR_TAB:
      return { ...state, activeSidebarTab: action.payload };
    case CREATE_FLOW_INPUT_MODAL:
      return { ...state, createFlowInputModal: action.payload };
    case CREATE_FLOW_MODAL:
      return { ...state, createFlowModal: action.payload };
    case SET_CATEGORY_CREATE_FLOW:
      return { ...state, categories: action.payload };
    case SET_NAME_CREATE_FLOW:
      return { ...state, name: action.payload };
    case CREATE_FIRST_SCREEN:
      return { ...state, firstNewScreen: action.payload };
    case UPDATE_SCREENS:
      return { ...state, screens: action.payload };
    case CHANGE_SELECTED_SCREEN:
      return { ...state, selectedScreen: action.payload };
    case UPDATE_TEXT_HEADING:
      return {
        ...state,
        screens: state.screens.map((screen) => ({
          ...screen,
          layout: {
            ...screen.layout,
            children: screen.layout.children.map((child) =>
              child.id === action.payload.id
                ? { ...child, [action.payload.field]: action.payload.value }
                : child
            ),
          },
        })),
      };
    case DELETE_ELEMENT:
      const { screenIndex, elementIndex } = action.payload;
      return {
        ...state,
        screens: state.screens.map((screen, index) => {
          if (index === screenIndex) {
            const newChildren = screen.layout.children.filter(
              (_, childIndex) => childIndex !== elementIndex
            );
            const updatedChildren = updateElementNames(newChildren);
            return {
              ...screen,
              layout: {
                ...screen.layout,
                children: updatedChildren,
              },
            };
          }
          return screen;
        }),
      };
    default:
      return state;
  }
};

export default WhatsAppFlows;
