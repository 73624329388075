export const getTranslation = (file, language, key) => {
    return file[language]?.[key] || key;
};

export const ModalChangeChatStatusTagLanguages = {
  en: {
    "Change chat status tags": "Change chat status tags",
    "Select tags": "Select tags",
    "Cancel": "Cancel",
    "Change": "Change",
    "Select a status tag": "Select a status tag",
    "Error saving chat status tag": "Error saving chat status tag",
    "Select": "Select"
  },
  es: {
    "Change chat status tags": "Cambiar etiquetas de estado chat",
    "Select tags": "Seleccione las etiquetas",
    "Cancel": "Cancelar",
    "Change": "Cambiar",
    "Select a status tag": "Seleccione una etiqueta de estado",
    "Error saving chat status tag": "Error al guardar la etiqueta de estado del chat",
    "Select": "Seleccione"
  },
  pt: {
    "Change chat status tags": "Alterar etiquetas de status do chat",
    "Select tags": "Selecione as etiquetas",
    "Cancel": "Cancelar",
    "Change": "Alterar",
    "Select a status tag": "Selecione uma etiqueta de status",
    "Error saving chat status tag": "Erro ao salvar a etiqueta de status do chat",
    "Select": "Selecione"
  }
};

export const ModalChatStatusTagsLanguages = {
  en: {
    "Chat status tags history": "Chat status tags history",
    "Close": "Close",
    "No tags assigned yet": "No tags assigned yet",
    "From": "From",
    "current": "current"
  },
  es: {
    "Chat status tags history": "Historial de etiquetas estado chat",
    "Close": "Cerrar",
    "No tags assigned yet": "No hay etiquetas asignadas aún",
    "From": "Desde",
    "current": "actual"
  },
  pt: {
    "Chat status tags history": "Histórico de etiquetas de status do chat",
    "Close": "Fechar",
    "No tags assigned yet": "Ainda não há etiquetas atribuídas",
    "From": "Desde",
    "current": "atual"
  }
};
