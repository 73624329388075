export const getTranslation = (file, language, key) => {
    return file[language]?.[key] || key;
};

export const BodyModalLanguages = {
  en: {
    "AvailableAgents": "Available Agents",
    "CurrentAgent": "current agent",
    "NoAgentAssigned": "No agent currently assigned"
  },
  es: {
    "AvailableAgents": "Agentes disponibles",
    "CurrentAgent": "agente actual",
    "NoAgentAssigned": "Sin agente asignado actualmente"
  },
  pt: {
    "AvailableAgents": "Agentes disponíveis",
    "CurrentAgent": "agente atual",
    "NoAgentAssigned": "Nenhum agente atualmente atribuído"
  }
};

export const FooterModalLanguages = {
  en: {
    "Close": "Close",
    "Save": "Save"
  },
  es: {
    "Close": "Cerrar",
    "Save": "Guardar"
  },
  pt: {
    "Close": "Fechar",
    "Save": "Salvar"
  }
};

export const HeaderModalLanguages = {
  en: {
    "AssignAgent": "Assign Agent"
  },
  es: {
    "AssignAgent": "Asignar Agente"
  },
  pt: {
    "AssignAgent": "Atribuir Agente"
  }
};



