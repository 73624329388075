export const getTranslation = (file, language, key) => {
    return file[language]?.[key] || key;
};

export const AttachedFilesLanguages = {
  en: {
    "Action": "Action",
    "Another Action": "Another Action",
    "Delete": "Delete"
  },
  es: {
    "Action": "Acción",
    "Another Action": "Otra Acción",
    "Delete": "Eliminar"
  },
  pt: {
    "Action": "Ação",
    "Another Action": "Outra Ação",
    "Delete": "Excluir"
  }
};

export const CrmIntegrationViewLanguages = {
  en: {
    "Loading": "Loading",
    "Contact Data": "Contact Data",
    "Name:": "Name:",
    "Phone:": "Phone:",
    "E-mail:": "E-mail:",
    "Chat": "Chat",
    "Messages saved successfully": "Messages saved successfully",
    "Error saving messages": "Error saving messages",
    "Choose a project": "Choose a project",
    "Project": "Project",
    "Interest Level": "Interest Level",
    "Choose a level": "Choose a level",
    "Save messages": "Save messages",
    "Saving messages": "Saving messages",
    "Register contact": "Register contact",
    "Enter contact details": "Enter contact details",
    "First Name": "First Name",
    "Last Name": "Last Name",
    "Email": "Email",
    "Phone": "Phone",
    "Registering contact": "Registering contact",
    "* All fields are required": "* All fields are required",
    "E-mail already registered in CRM": "E-mail already registered in CRM"
  },
  es: {
    "Loading": "Cargando",
    "Contact Data": "Datos del contacto",
    "Name:": "Nombre:",
    "Phone:": "Teléfono:",
    "E-mail:": "E-mail:",
    "Chat": "Chat",
    "Messages saved successfully": "Mensajes guardados con éxito",
    "Error saving messages": "Error al guardar mensajes",
    "Choose a project": "Elige un proyecto",
    "Project": "Proyecto",
    "Interest Level": "Nivel de Interés",
    "Choose a level": "Elige un nivel",
    "Save messages": "Guardar mensajes",
    "Saving messages": "Guardando mensajes",
    "Register contact": "Registrar contacto",
    "Enter contact details": "Ingresa los datos de contacto",
    "First Name": "Nombres",
    "Last Name": "Apellidos",
    "Email": "Correo electrónico",
    "Phone": "Telefono",
    "Registering contact": "Registrando contacto",
    "* All fields are required": "* Todos los campos son requeridos",
    "E-mail already registered in CRM": "E-mail ya registrado en CRM"
  },
  pt: {
    "Loading": "Carregando",
    "Contact Data": "Dados do contato",
    "Name:": "Nome:",
    "Phone:": "Telefone:",
    "E-mail:": "E-mail:",
    "Chat": "Chat",
    "Messages saved successfully": "Mensagens salvas com sucesso",
    "Error saving messages": "Erro ao salvar mensagens",
    "Choose a project": "Escolha um projeto",
    "Project": "Projeto",
    "Interest Level": "Nível de Interesse",
    "Choose a level": "Escolha um nível",
    "Save messages": "Salvar mensagens",
    "Saving messages": "Salvando mensagens",
    "Register contact": "Registrar contato",
    "Enter contact details": "Insira os detalhes do contato",
    "First Name": "Nome",
    "Last Name": "Sobrenome",
    "Email": "E-mail",
    "Phone": "Telefone",
    "Registering contact": "Registrando contato",
    "* All fields are required": "* Todos os campos são obrigatórios",
    "E-mail already registered in CRM": "E-mail já registrado no CRM"
  }
};

export const SelectContactLanguages = {
  en: {
    "Demo": "Demo",
    "Action": "Action",
    "Another Action": "Another Action",
    "Delete": "Delete"
  },
  es: {
    "Demo": "Demostración",
    "Action": "Acción",
    "Another Action": "Otra Acción",
    "Delete": "Eliminar"
  },
  pt: {
    "Demo": "Demonstração",
    "Action": "Ação",
    "Another Action": "Outra Ação",
    "Delete": "Excluir"
  }
};

export const UserProfileSidebarLanguages = {
  en: {
    "Contact Data": "Contact Data",
    "Name": "Name",
    "Email": "Email",
    "Phone": "Phone",
    "Brand": "Brand",
    "Extra Field": "Extra Field",
    "Edit Contact": "Edit Contact",
    "Assigned Agent Data": "Assigned Agent Data",
    "Assigned agent": "Assigned agent",
    "No agent assigned": "No agent assigned",
    "From": "From",
    "Actions": "Actions",
    "Assign new agent": "Assign new agent",
    "Release": "Release",
    "View assignment history": "View assignment history",
    "Members": "Members",
    "Admin": "Admin",
    "Chat Status": "Chat Status",
    "Pending": "Pending",
    "In progress": "In progress",
    "Success": "Success",
    "Mark as success": "Mark as success",
    "Release chat": "Release chat",
    "Archive success": "Archive success",
    "Chat status tags": "Chat status tags",
    "Current tags": "Current tags",
    "View history": "View history",
    "Change tags": "Change tags",
    "Registration origin": "Registration origin",
    "Tags": "Tags",
    "Chat tag": "Chat tag",
    "Mantra Integration": "Mantra Integration",
    "Chat expires in": "Chat expires in",
    "Chat has expired": "Chat has expired",
  },
  es: {
    "Contact Data": "Datos del contacto",
    "Name": "Nombre",
    "Email": "Correo electrónico",
    "Phone": "Teléfono",
    "Brand": "Marca",
    "Extra Field": "Campo Extra",
    "Edit Contact": "Editar Contacto",
    "Assigned Agent Data": "Datos del Agente Asignado",
    "Assigned agent": "Agente asignado",
    "No agent assigned": "Sin agente asignado",
    "From": "Desde",
    "Actions": "Acciones",
    "Assign new agent": "Asignar nuevo agente",
    "Release": "Liberar",
    "View assignment history": "Ver historial de asignaciones",
    "Members": "Miembros",
    "Admin": "Administrador",
    "Chat Status": "Estado del chat",
    "Pending": "Pendiente",
    "In progress": "En proceso",
    "Success": "Éxito",
    "Mark as success": "Marcar como éxito",
    "Release chat": "Liberar chat",
    "Archive success": "Archivar éxito",
    "Chat status tags": "Etiquetas de estado chat",
    "Current tags": "Etiquetas actuales",
    "View history": "Ver historial",
    "Change tags": "Cambiar etiquetas",
    "Registration origin": "Origen registro",
    "Tags": "Etiquetas",
    "Chat tag": "Etiqueta de chat",
    "Mantra Integration": "Mantra Integración",
    "Chat expires in": "El chat expira dentro de",
    "Chat has expired": "El chat ha expirado",
  },
  pt: {
    "Contact Data": "Dados do contato",
    "Name": "Nome",
    "Email": "E-mail",
    "Phone": "Telefone",
    "Brand": "Marca",
    "Extra Field": "Campo Extra",
    "Edit Contact": "Editar Contato",
    "Assigned Agent Data": "Dados do Agente Atribuído",
    "Assigned agent": "Agente atribuído",
    "No agent assigned": "Sem agente atribuído",
    "From": "De",
    "Actions": "Ações",
    "Assign new agent": "Atribuir novo agente",
    "Release": "Liberar",
    "View assignment history": "Ver histórico de atribuições",
    "Members": "Membros",
    "Admin": "Administrador",
    "Chat Status": "Status do chat",
    "Pending": "Pendente",
    "In progress": "Em andamento",
    "Success": "Sucesso",
    "Mark as success": "Marcar como sucesso",
    "Release chat": "Liberar chat",
    "Archive success": "Arquivar sucesso",
    "Chat status tags": "Tags de status do chat",
    "Current tags": "Tags atuais",
    "View history": "Ver histórico",
    "Change tags": "Alterar tags",
    "Registration origin": "Origem registro",
    "Tags": "Etiquetas",
    "Chat tag": "Etiqueta de chat",
    "Mantra Integration": "Mantra Integración",
    "Chat expires in": "O chat expira em",
    "Chat has expired": "O chat expirou",
  }
};

export const AiAnalysisViewLanguages = {
  en: {
    "AI Analysis": "AI Analysis",
    "conversationSummary": "Conversation Summary",
    "classificationMatrix": "Classification Matrix",
    "nerAnalysis": "NER Analysis",
    "sentimentAnalysis": "Sentiment Analysis",
  },
  es: {
    "AI Analysis": "Análisis IA",
    "conversationSummary": "Resumen de la conversación",
    "classificationMatrix": "Matriz de Clasificación",
    "nerAnalysis": "Análisis NER",
    "sentimentAnalysis": "Análisis de Sentimiento",
  },
  pt: {
    "AI Analysis": "Análise IA",
    "conversationSummary": "Resumo da conversação",
    "classificationMatrix": "Matriz de Clasificação",
    "nerAnalysis": "Análise NER",
    "sentimentAnalysis": "Análise de Sentimento",
  }
}
