export const getTranslation = (file, language, key) => {
    return file[language]?.[key] || key;
};

export const indexLanguages = {
  en: {
    "AssignmentHistory": "Assignment History",
    "Close": "Close",
    "AssignedToAgent": "Assigned to agent",
    "On": "on",
    "ContactHasNoAssignments": "Contact has no assignments.",
    "ByAPIIntegration": "by API integration",
    "ByAdminUser": "by Admin user",
    "ByAgentUser": "by Agent user",
    "ByAutomaticAssignment": "by automatic assignment",
    "ByChatbot": "by chatbot",
    "ByAPI": "by API",
    "WithID": "with ID"
  },
  es: {
    "AssignmentHistory": "Historial Asignaciones",
    "Close": "Cerrar",
    "AssignedToAgent": "Asignado al agente",
    "On": "en",
    "ContactHasNoAssignments": "Contacto no ha tenido asignaciones.",
    "ByAPIIntegration": "por API integración",
    "ByAdminUser": "por usuario Admin",
    "ByAgentUser": "por usuario Agente",
    "ByAutomaticAssignment": "por asignación automática",
    "ByChatbot": "por chatbot",
    "ByAPI": "por API",
    "WithID": "con ID"
  },
  pt: {
    "AssignmentHistory": "Histórico de Atribuições",
    "Close": "Fechar",
    "AssignedToAgent": "Atribuído ao agente",
    "On": "em",
    "ContactHasNoAssignments": "O contato não tem atribuições.",
    "ByAPIIntegration": "por integração API",
    "ByAdminUser": "por usuário Admin",
    "ByAgentUser": "por usuário Agente",
    "ByAutomaticAssignment": "por atribuição automática",
    "ByChatbot": "por chatbot",
    "ByAPI": "por API",
    "WithID": "com ID"
  }
};
