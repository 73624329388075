import React from "react";
import { ModalHeader } from "reactstrap";
import { useSelector } from 'react-redux';
import { getTranslation, HeaderModalLanguages } from "./language";

function HeaderModal({
  toggle,
}) {
  const language = useSelector((state) => state.Group.language);
  const translate = (key) => getTranslation(HeaderModalLanguages, language, key);

  return (
    <ModalHeader
      tag="h5"
      className="font-size-16"
      toggle={() => {
        toggle();
      }}
    >
      {translate("AssignAgent")}
    </ModalHeader>
  );
}

export default HeaderModal;
